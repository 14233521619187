<template>
    <div class="">
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start align-items-start mr-5 px-2" style="gap: 2px">
                <b-avatar size="sm" variant="primary" class="mr-2" rounded></b-avatar>
                <h4 class="mb-0 mr-2" v-if="customer.reasonTypeId == 1">
                    {{ $buildName(customer) }}
                </h4>
                <h4 class="mb-0 mr-2" v-else>{{ customer.businessName }}</h4>
                <b-badge :variant="customer.encodedKey == null || customer.encodedKey == '' ? 'danger' : 'success'">
                    <i class="mdi mdi-sync"></i>
                    Mambu
                </b-badge>

                <b-badge :variant="'dark'" v-if="customer.blacklisted">
                    <i class="mdi mdi-alert-circle-outline"></i>
                    Blacklisted
                </b-badge>
                <b-badge :variant="'dark'" v-if="customer.isDeceased">
                    <i class="mdi mdi-alert-circle-outline"></i>
                    Deceased
                </b-badge>
                <b-badge :variant="'dark'" v-if="customer.pep">
                    <i class="mdi mdi-alert-circle-outline"></i>
                    PEP
                </b-badge>
            </div>
            <div class="d-flex" style="gap: 20px">
                <div class="d-flex flex-column align-items-end">
                    <p v-if="currentRoute.includes('/applications')" class="mb-0">
                        <strong>Date created: </strong>{{ formatDate(loanApplication.createAt) }}
                    </p>
                    <p v-else class="mb-0"><strong>Date created: </strong>{{ formatDate(customer.createDate) }}</p>

                    <div
                        class="mb-0 d-flex flex-column align-items-end"
                        v-if="currentRoute.includes('/applications') && loanApplication"
                    >
                        <p class="mb-2"><strong>Application number:</strong> {{ loanApplication.applicationCode }}</p>
                    </div>
                    <p v-else class="d-flex">
                        <template v-if="customer.reasonTypeId == 1">
                            <strong class="mr-2">National ID:</strong>
                            <ItemText :text="nationalId" />
                        </template>
                        <template v-if="customer.reasonTypeId == 2">
                            <strong class="mr-2">Business ID:</strong>
                            <ItemText :text="customer.businessId" />
                        </template>
                    </p>
                </div>
                <div
                    v-if="
                        currentRoute.includes('/applications') &&
                        hasPermissionToApprove &&
                        loanApplication.loanProcessStatusLog.currentStatusCode != '220'
                    "
                >
                    <b-button variant="primary" class="px-4" @click="refreshInfo">
                        <i class="mdi mdi-sync"></i> Refresh
                    </b-button>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-start pt-2">
            <Stepper v-if="currentRoute.includes('/applications')" />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Stepper from '@/components/Stepper.vue';
import * as dayjs from 'dayjs';
import ItemText from '@/components/ItemText.vue';
import { validatePermission } from '../utils/auth';
export default {
    components: {
        Stepper,
        ItemText,
    },
    data() {
        return {
            currentRoute: window.location.pathname,
        };
    },
    computed: {
        ...mapGetters({
            currentCustomer: 'customer/getCustomer',
            loanApplication: 'loan/applications/getLoanApplication',
        }),
        nationalId() {
            return this.customer?.additionalDetails?.nationalID ?? '';
        },
        hasPermissionToApprove() {
            const status = this.loanApplication?.loanProcessStatusLog?.currentStatusCode;
            if (status == '100' || status == '110') {
                return validatePermission('/LoanApplications-Update-Approved');
            }
            return true;
        },
        customer() {
            if (this.currentRoute.includes('/applications')) {
                return this.loanApplication.applicantInfo;
            }
            return this.currentCustomer;
        },
    },
    methods: {
        formatDate(date) {
            if (!date) return;
            return dayjs(date).format('YYYY/MM/DD');
        },
        async refreshInfo() {
            this.loading = true;
            await this.$store.dispatch('loan/applications/refreshApplicantsInfo', this.$route.params.id);
            await this.$store.dispatch('loan/applications/get_loanApplicationById', this.$route.params.id);
            this.loading = false;
        },
    },
};
</script>
